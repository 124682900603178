import { dayjs } from "~/utils/dayjs";

type TimeFormat = "dynamic" | "time" | "shortDate" | "date" | "dateTime" | "full";

type Timezone = "utc" | "central";

const timezoneMap: Record<Timezone, string> = {
  utc: "UTC",
  central: "America/Chicago",
};

export function formatTime(
  date: string | number | Date | dayjs.Dayjs,
  format: TimeFormat,
  timezone?: Timezone,
) {
  if (format === "dynamic") {
    // Less than 1 minute -> Just now
    if (dayjs().diff(date, "minutes") <= 1) return "Just now";
    // Is same day -> h:mm AM/PM
    if (dayjs().isSame(date, "days")) return formatTime(date, "time");
    // Is same year -> MMM D
    if (dayjs().isSame(date, "years")) return formatTime(date, "shortDate");
    // Is different year -> MMM D, YYYY
    return formatTime(date, "date");
  }

  const dateWithTimezone = timezone ? dayjs(date).tz(timezoneMap[timezone]) : dayjs(date);
  switch (format) {
    case "time":
      return dateWithTimezone.format("h:mm A");
    case "shortDate":
      return dateWithTimezone.format("MMM D");
    case "date":
      return dateWithTimezone.format("MMM D, YYYY");
    case "dateTime":
      return dateWithTimezone.format("MMM D, YYYY, h:mm A");
    case "full":
    default:
      return dateWithTimezone.format("ddd, MMM D, YYYY, h:mm A");
  }
}
